import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequesterService } from 'src/app/common/services/requester.service';
import { DeliveryAddressAddUpdateComponent } from '../delivery-address-add-update/delivery-address-add-update.component';

@Component({
  selector: 'app-delivery-addresses',
  templateUrl: './delivery-addresses.component.html',
  styleUrl: './delivery-addresses.component.scss'
})
export class DeliveryAddressesComponent implements OnInit{
  allAddress: any[] = [];
  isVisible = false;
  editId: string | null = null;

  constructor(
    private requesterService: RequesterService,
    private nzModalService: NzModalService,
    private fb: FormBuilder,
    private msg: NzMessageService
  ) {

  }

  ngOnInit(): void {
    this.getAllAddresses();
  }

  getAllAddresses(): void {
    this.requesterService.request('get', 'owner-companies/delivery-addresses').subscribe((res) => {
      this.allAddress = res.data;
    });
  }



  deleteAddress(addressId: string): void {
    const address = this.allAddress.find((a) => a._id === addressId);
    this.nzModalService.confirm({
      nzTitle: 'Are you sure you want to delete this address?',
      nzContent: `<strong>${address.title}</strong><br>${address.address}`,
      nzOnOk: () => {
        this.requesterService.request('delete', `owner-companies/delivery-addresses/${addressId}`).subscribe((res) => {
          console.log(res);
          this.getAllAddresses();
        });
      }
    });
  }

  openAddEditModal(addressId?: string): void {
    // console.log(address);
    this.isVisible = true;
    this.editId = addressId;
    const modal = this.nzModalService.create({
      nzTitle: addressId ? 'Edit Address' : 'Add Address',
      nzContent: DeliveryAddressAddUpdateComponent,
      nzData: {
        editId: addressId,
        address: addressId ? this.allAddress.find((a) => a._id === addressId) : null
      },
      nzFooter: null
    });

    modal.afterClose.subscribe((res) => {
      console.log(res);
      if (res) {
        this.getAllAddresses();
      }
    } );
  }
}
