import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { RequesterService } from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: any;

  constructor(private requesterService: RequesterService) { }

  async getStripe() {
    if (!this.stripe) {
      this.stripe = await loadStripe('pk_live_51P1MKNItVaMpopZmz3qnFurAWQQqTA7BGuOXKDneslc6HGWCEk6PM2NFHgjnMJNEGOhrrw2whzRFD9HxcFgAiFCK00C84W4wFn');
    }
    return this.stripe;
  }

  addCard(tokenId: string) {
    return this.requesterService.request('post', 'payments/stripe/buyer/payment-methods', { token: tokenId }).toPromise();
  }
}
