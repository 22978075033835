import {Component, Inject, Input, OnInit} from '@angular/core';
import {NzUploadChangeParam} from 'ng-zorro-antd/upload';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';

import {RequesterService} from '../../../common/services/requester.service';

@Component({
  selector: 'app-job-complete-modal',
  templateUrl: './job-complete-modal.component.html',
  styleUrls: ['./job-complete-modal.component.scss']
})
export class JobCompleteModalComponent implements OnInit {
  rfqId: string;
  from: string = 'workspace';
  current = 0;
  listOfFiles: any[] = [];
  loading = false;
  ratingsArr = [
    {
      name: 'Timeliness',
      icon: 'clock-circle',
      value: 0,
      disabled: false,
    },
    {
      name: 'Communication',
      icon: 'interaction',
      value: 0,
      disabled: false,
    },
    {
      name: 'Cost',
      icon: 'dollar',
      value: 0,
      disabled: false,
    },
    {
      name: 'Performance',
      icon: 'dashboard',
      value: 0,
      disabled: false,
    },
    {
      name: 'Documentation',
      icon: 'file-done',
      value: 0,
      disabled: false,
    }
  ];
  feedback: string = '';
  isLoading = false;
  rfqDetails: any;
  rfqLoading = true;
  rated = false;
  completed = false;
  onIndexChange(index) {
    this.current = index;
  }
  constructor(
    private msg: NzMessageService,
    private notificationService: NzNotificationService,
    private modalRef: NzModalRef<any>,
    private requesterService: RequesterService,
    @Inject(NZ_MODAL_DATA) public modalData: any
  ) {
    this.rfqId = modalData.rfqId;
    this.from = modalData.from;
  }

  ngOnInit() {
    console.log('RFQ ID_----------->', this.rfqId);
    this.getRfqDetails();
  }

  get isRated() {
    return this.ratingsArr.map(x => x.value).some(x => !!x);
  }

  pre(): void {
    this.current -= 1;
  }

  next(): void {
    this.current += 1;
  }

  done(): void {
    console.log('done');
    this.modalRef.close(true);
  }

  complete(): void {
    this.isLoading = true;

    const completedObj = {
      jobStatus: 11,
      jobCompletionAttachments: this.listOfFiles,
      serviceReportRequired: this.rfqDetails.serviceReportRequired,
      ratings: this.ratingsArr.map(x => ({name: x.name, rating: x.value})),
      feedback: this.feedback
    };
    if (!this.isRated) {
      delete completedObj.ratings;
      delete completedObj.feedback;
    } else {
      this.rated = true;
    }

    this.requesterService.request('put', `jobs/rfqs/${this.rfqId}`, { ...completedObj}).subscribe(data => {
      console.log(data);
      if (this.completed) {
        this.notificationService.success('Success', 'Job has been updated!');
      }
      if(!this.completed || this.current === 1) {
        this.current += 1;
      }

      this.isLoading = false;
    });
  }

  getRfqDetails(): void {
    this.requesterService.request('get', 'jobs/rfqs/' + this.rfqId).subscribe(data => {
      this.rfqDetails = data.data;
      this.rfqLoading = false;
      if (this.rfqDetails?.jobStatus > 10) {
        this.completed = true;
        this.listOfFiles = this.rfqDetails.jobCompletionAttachments;
        if (this.rfqDetails.ratings && this.rfqDetails.ratings.length) {
          this.rated = true;
          this.ratingsArr.map((x, i) => {
            x.value = this.rfqDetails.ratings[i].rating;
            x.disabled = true;
          });
          this.feedback = this.rfqDetails.feedback;
          this.current = 2;
        } else {
          this.current = 1;
        }
      }
    });
  }

}
