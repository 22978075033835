import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NzFormModule } from "ng-zorro-antd/form";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
// import {NgZorroAntdModule} from 'ng-zorro-antd';
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzListModule } from "ng-zorro-antd/list";
import { NzAnchorModule } from "ng-zorro-antd/anchor";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { RoundUp } from "../pipes/RoundUp";
import { TimeAgoPipe } from "../pipes/time-ago.pipe";
// import {PushNotificationsModule} from 'ng-push';
import { LocalFilterPipe } from "../pipes/local-filter.pipe";
import { FilterByArrayFieldPipe } from "../pipes/filter-by-array-field.pipe";
import { FilterByDatePipe } from "../pipes/filter-by-date.pipe";
import { ResizableModule } from "angular-resizable-element";
import { FilePreviewComponent } from "./file-preview/file-preview.component";
import { MediaBoxComponent } from "./media-box/media-box.component";
import { UploadBoxComponent } from "./upload-box/upload-box.component";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzNotificationModule } from "ng-zorro-antd/notification";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzRateModule } from "ng-zorro-antd/rate";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzMentionModule } from "ng-zorro-antd/mention";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzBackTopModule } from 'ng-zorro-antd/back-top';
import { PdfPreviewRenderComponent } from './pdf-preview-render/pdf-preview-render.component';
import { KtdGridModule } from "@katoid/angular-grid-layout";
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { ApexOptions, NgApexchartsModule } from "ng-apexcharts";
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { AttenderAILoaderComponent } from './attender-ailoader/attender-ailoader.component';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { TermsConditionModalComponent } from './terms-condition-modal/terms-condition-modal.component';
import { AddCardComponent } from "src/app/main/billing/add-card/add-card.component";
import { AddEditTermsModalComponent } from "src/app/main/terms/add-edit-terms-modal/add-edit-terms-modal.component";
import { ViewSingleTermComponent } from './view-single-term/view-single-term.component';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { DeliveryAddressesComponent } from "src/app/corporate/delivery-addresses/delivery-addresses.component";
import { DeliveryAddressAddUpdateComponent } from "src/app/corporate/delivery-address-add-update/delivery-address-add-update.component";

// take a variable where declare all the module thats want to share with
const Modules = [
  CommonModule,
  NzEmptyModule,
  NzProgressModule,
  NzBackTopModule,
  NzDrawerModule,
  NzDatePickerModule,
  NzTimePickerModule,
  NzMentionModule,
  NzRateModule,
  NzSkeletonModule,
  NzCheckboxModule,
  NzTableModule,
  NzDividerModule,
  NzAlertModule,
  NzModalModule,
  NzInputNumberModule,
  NzIconModule,
  NzTagModule,
  NzSpinModule,
  NzNotificationModule,
  NzCardModule,
  NzStepsModule,
  NzMenuModule,
  NzMessageModule,
  NzButtonModule,
  NzCollapseModule,
  NzUploadModule,
  NzBadgeModule,
  NzInputModule,
  NzAvatarModule,
  NzPopconfirmModule,
  NzPopoverModule,
  NzDropDownModule,
  NzToolTipModule,
  NzBreadCrumbModule,
  NzLayoutModule,
  NzFormModule,
  ReactiveFormsModule,
  NzGridModule,
  NzSelectModule,
  FormsModule,
  NzPageHeaderModule,
  NzResultModule,
  NzListModule,
  NzAnchorModule,
  // RxReactiveFormsModule,
  NzCarouselModule,
  // ResizableModule,
  NzTabsModule,
  KtdGridModule,
  NgApexchartsModule,
  NzRadioModule,
  NzDescriptionsModule,
  NzSpaceModule,
  NzAutocompleteModule,
  NzCommentModule,
  NzPaginationModule,
  NzSwitchModule,

  // PushNotificationsModule
];

@NgModule({
  declarations: [
    RoundUp,
    TimeAgoPipe,
    LocalFilterPipe,
    FilterByArrayFieldPipe,
    FilterByDatePipe,
    FilePreviewComponent,
    MediaBoxComponent,
    UploadBoxComponent,
    PdfPreviewRenderComponent,
    AttenderAILoaderComponent,
    TermsConditionModalComponent,
    AddCardComponent,
    AddEditTermsModalComponent,
    ViewSingleTermComponent,
    DeliveryAddressesComponent,
    DeliveryAddressAddUpdateComponent

  ],
  imports: [...Modules],
  exports: [
    ...Modules,
    RoundUp,
    TimeAgoPipe,
    LocalFilterPipe,
    FilterByArrayFieldPipe,
    FilterByDatePipe,
    FilePreviewComponent,
    MediaBoxComponent,
    UploadBoxComponent,
    AttenderAILoaderComponent,
    TermsConditionModalComponent,
    AddCardComponent,
    AddEditTermsModalComponent,
    DeliveryAddressesComponent,
    DeliveryAddressAddUpdateComponent
  ],
})
export class SharedModuleModule { }
