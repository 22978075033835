// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addressItem {
  margin-bottom: 12px;
  display: grid;
}

.addressItem nz-card {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/corporate/delivery-addresses/delivery-addresses.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".addressItem {\n  margin-bottom: 12px;\n  display: grid;\n}\n\n.addressItem nz-card {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
