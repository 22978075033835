import { Component, inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { RequesterService } from 'src/app/common/services/requester.service';

@Component({
  selector: 'app-delivery-address-add-update',
  templateUrl: './delivery-address-add-update.component.html',
  styleUrl: './delivery-address-add-update.component.scss'
})
export class DeliveryAddressAddUpdateComponent {
  @Input() editId: string | null = null;
  @Input() address: any;
  addressForm: FormGroup;
  readonly nzModalData: any = inject(NZ_MODAL_DATA);
  loading = false;

  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private nzModalRef: NzModalRef,
    private msg: NzMessageService
  ) {
    this.addressForm = new FormGroup({});
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.addressForm = this.fb.group({
      title: [null, [Validators.required]],
      address: [null, [Validators.required]],
    });

    console.log(this.nzModalData);
    this.editId = this.nzModalData.editId;
    this.address = this.nzModalData.address;
    this.addressForm.patchValue(this.address);
  }

  handleCancel(): void {
    this.nzModalRef.destroy();
  }

  handleOk(): void {

    if (this.addressForm.valid) {
      console.log('submit', this.addressForm.value);
      this.loading = true;

      if(this.editId){
        // update
        this.requesterService.request('put', `owner-companies/delivery-addresses/${this.editId}`, this.addressForm.value).subscribe((res) => {
          console.log(res);
          this.addressForm.reset();
          this.loading = false;
          this.nzModalRef.destroy(res.data);
        } , (err) => {
          console.log(err);
          this.msg.error(err.error.message);
        } );
      } else {
        // create
        this.requesterService.request('post', 'owner-companies/delivery-addresses', this.addressForm.value).subscribe((res) => {
          console.log(res);
          this.addressForm.reset();
          this.loading = false;
          this.nzModalRef.destroy(res.data);
        } , (err) => {
          console.log(err);
          this.msg.error(err.error.message);
        } );
      }
    } else {
      Object.values(this.addressForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
